import { createFileRoute, redirect } from "@tanstack/react-router"

export const Route = createFileRoute("/_main-layout/tier/")({
  beforeLoad: ({ params, search }) => {
    // force redirect to dashboard
    throw redirect({
      to: "/dashboard",
      params,
      search
    })
  }
})
