import mixpanel, { OverridedMixpanel } from "mixpanel-browser"

export type MixpanelToken = {
  projectToken: string
}

export const initMixpanel = (
  { projectToken }: MixpanelToken,
  isEnableTrackingDebug: boolean
): OverridedMixpanel => {
  mixpanel.init(projectToken, {
    debug: isEnableTrackingDebug
  })
  return mixpanel
}

export default mixpanel
