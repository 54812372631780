import { useNavigate } from "@tanstack/react-router"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

//icons

//components
import { AspectRatio } from "@/components/ui/aspect-ratio"

//utils
import { currencyFormat } from "@/utils/number"

//APIs
import { TokenType } from "@/api/coupon.api"

export const Label = (props: React.ComponentProps<"div">) => {
  const { id, title } = props
  return (
    <div
      data-testid={`product-label-${id}`}
      className="flex gap-1 bg-tmn-orange-tint-0 rounded-sm py-[2px] px-2"
    >
      <span className="tag-small text-white">{title}</span>
    </div>
  )
}

const MAX_HOURS_NEW_PRODUCT = 72
const MIN_SALE_PERCENTAGE = 10
// const MIN_NEAR_END_DAYS = 1;

export interface Product {
  id: string
  title: string
  desc: string
  image: string
  status: string
  stock: number
  discountedPrice: number
  price: number
  type: string
  startDate: string
  endDate: string
}

interface Props {
  data: Product
}

const ProductCard = ({ data }: Props) => {
  const { id, title, desc, image, stock, discountedPrice, price, type, startDate } = data
  const navigate = useNavigate()
  const { t } = useTranslation(["exchange"])

  const onClickProduct = (listingId: string, type: string) => {
    if (type === TokenType.Deals) {
      navigate({ to: `/exchange/products/${listingId}` })
    } else {
      navigate({ to: `/exchange/cashback/${listingId}` })
    }
  }

  const isDiscounted = discountedPrice < price
  const actualPrice = isDiscounted ? discountedPrice : price

  const discountPercentage = ((price - discountedPrice) / price) * 100

  const targetDate = dayjs(startDate)
  const currentDate = dayjs()
  const hoursDifference = currentDate.diff(targetDate, "hour")

  const criteria: Record<string, boolean> = {
    isNew: hoursDifference < MAX_HOURS_NEW_PRODUCT,
    isSale: discountPercentage >= MIN_SALE_PERCENTAGE,
    isNearEnd: false,
    // isNearEnd:
    //   dayjs(data.endDate).diff(currentDate, "day") <= MIN_NEAR_END_DAYS,
    isRecommended: false
  }

  const labels: Record<string, { title: string; priority: number }> = {
    isNew: {
      title: t("newLabel"),
      priority: 3
    },
    isSale: {
      title: t("saleLabel"),
      priority: 4
    },
    isNearEnd: {
      title: t("nearEndLabel"),
      priority: 5
    },
    isRecommended: {
      title: t("recommendedLabel"),
      priority: 2
    }
  }

  const getTitleLabel = () => {
    let selectedLabel = ""
    let maxPriority = -1

    for (const key in criteria) {
      if (criteria[key] && labels[key].priority > maxPriority) {
        selectedLabel = labels[key].title
        maxPriority = labels[key].priority
      }
    }

    return selectedLabel
  }

  const titleLabel = getTitleLabel()

  return (
    <div
      data-testid={`product-card-container-${id}`}
      className="tmn-card-shadow bg-white rounded-lg w-full cursor-pointer"
      onClick={() => onClickProduct(id, type)}
    >
      <div className="relative">
        <AspectRatio ratio={4 / 3}>
          <img
            data-testid={`product-image-${id}`}
            className="w-full h-full rounded-t-lg"
            alt={`image-${id}`}
            src={image}
          />
        </AspectRatio>
        {titleLabel && (
          <div className="absolute top-2 right-2">
            <Label id={id} title={titleLabel} />
          </div>
        )}
        {stock === 0 && (
          <div
            data-testid={`product-out-of-stock-overlay-${id}`}
            className="absolute top-0 bg-[#000000ad] rounded-t-lg w-full h-full flex justify-center items-center"
          >
            <span className="body-strong text-white">{t("fullyRedeemedOverlay")}</span>
          </div>
        )}
      </div>
      <div className="p-2">
        <span
          data-testid={`product-title-text-${id}`}
          className="button-small text-tmn-black-tint-50 line-clamp-1 text-ellipsis mb-1"
        >
          {title}
        </span>
        <span
          data-testid={`product-desc-text-${id}`}
          className="body text-tmn-black-tint-10 h-10 line-clamp-2 text-ellipsis"
        >
          {desc}
        </span>
        <div className="flex items-center gap-1 mt-2">
          <img
            src="/icons/ic_tmn_coin.png"
            alt="tmn coin"
            style={{ width: "16px", height: "16px" }}
          />
          <span data-testid={`product-price-text-${id}`} className="body-strong">
            {currencyFormat(actualPrice.toString())}
          </span>
          {isDiscounted && (
            <span
              data-testid={`product-original-price-text-${id}`}
              className="body line-through text-[#c5c6cc]"
            >
              {currencyFormat(price.toString())}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProductCard
