import { useInfiniteQuery } from "@tanstack/react-query"

//APIs
import { getMyCoupons } from "@/api/coupon.api"

const FETCH_MY_COUPONS = "FETCH_MY_COUPONS"

interface Props {
  limit?: number
  enabled?: boolean
}

const useFetchMyCoupons = (props: Props) => {
  const { limit = 10, enabled = true } = props
  return useInfiniteQuery({
    queryKey: [FETCH_MY_COUPONS],
    queryFn: async ({ pageParam }) => {
      const skip = limit * pageParam

      const response = await getMyCoupons({
        tokenType: "Deals",
        dealStatuses: "Published",
        sort: "asDeal__redeemEndDate:asc",
        skip,
        limit
      })

      const nextPage = response.items.length > 0 ? pageParam + 1 : undefined
      return { data: response, nextPage }
    },
    initialPageParam: 0,
    getNextPageParam: lastPage => lastPage.nextPage ?? undefined,
    refetchOnWindowFocus: "always",
    enabled
  })
}

export default useFetchMyCoupons
