import { Trans, useTranslation } from "react-i18next"

const UnderMA = () => {
  const { t } = useTranslation(["error"], { keyPrefix: "error.partialMaintenance" })

  return (
    <div className="h-screen">
      <div className="flex flex-col items-center gap-6 h-full pt-32">
        <img
          src="/images/fail.png"
          alt="We're down for maintenance"
          width={281}
          height={184}
        />
        <div className="flex flex-col items-center max-w-[343px] gap-2">
          <h2 className="headline">{t("title")}</h2>
          <span className="body-large text-tmn-black-tint-30 text-center">
            <Trans>{t("description")}</Trans>
          </span>
        </div>
      </div>
    </div>
  )
}

export default UnderMA
