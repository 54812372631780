import braze from "@/utils/BrazeConfig"
import mixpanel from "@/utils/MixpanelConfig"
import dayjs from "dayjs"

import { faro } from "@/utils/FaroConfig"

const getExpiryDate = (expiry: string, isBraze: boolean) => {
  if (expiry && dayjs(expiry).isValid() && expiry !== "1970-01-01T00:00:00Z") {
    return (
      dayjs
        .utc(expiry)
        .subtract(1, "second")
        .locale("en")
        // Date format for Braze see here: https://www.braze.com/docs/api/objects_filters/user_attributes_object
        .format(isBraze ? "YYYY-MM-DDTHH:mm:ss" : "YYYY-MM-DD")
    )
  }
  return ""
}

/**
 * Track the home page visit(Dashboard page)
 */
export const trackHomePage = async ({
  tmnId,
  balance,
  expiry,
  totalCoins
}: {
  tmnId: string
  totalCoins: number
  balance: number
  expiry: string
}) => {
  const expiryDate = getExpiryDate(expiry, false)
  const brazeExpiryDate = getExpiryDate(expiry, true)

  // Faro (Grafana) set user attributes
  // NOTICE: this set user only call in dashboard page if user open app with deeplink and got error we cannot know which user is.
  if (faro) {
    faro.api.setUser({
      id: tmnId
    })
  }

  // Mixpanel
  mixpanel.identify(tmnId)

  mixpanel.track("TMN Coin - Landing", {
    "Total Coin": totalCoins,
    abc_TMC_balance: balance,
    abc_TMC_expiry: expiryDate
  })

  mixpanel.people.set({
    abc_TMC_balance: balance,
    abc_TMC_expiry: expiryDate
  })

  // Braze
  braze.changeUser(tmnId)
  braze.openSession()
  braze.logCustomEvent("TMN Coin - Landing", {
    "Total Coin": totalCoins,
    abc_TMC_balance: balance,
    abc_TMC_expiry: brazeExpiryDate
  })

  braze.getUser()?.setCustomUserAttribute("abc_TMC_balance", balance)
  braze.getUser()?.setCustomUserAttribute("abc_TMC_expiry", brazeExpiryDate)
}

export const trackTMNCoinClickButton = async ({ buttonName }: { buttonName: string }) => {
  // Mixpanel
  mixpanel.track("TMN Coin - Click Button", {
    "Button name": buttonName
  })
  // Braze
  braze.logCustomEvent("TMN Coin - Click Button", {
    "Button name": buttonName
  })
}

export const trackTMNCoinError = async ({
  tmnId,
  errorCode,
  errorMessage
}: {
  tmnId: string
  errorCode: string
  errorMessage: string
}) => {
  // Mixpanel
  if (tmnId) {
    mixpanel.identify(tmnId)
  }

  mixpanel.track("TMN Coin - Error", {
    "Error Code": errorCode,
    "Error Message": errorMessage
  })
  // Braze
  if (tmnId) {
    braze.changeUser(tmnId)
  }
  braze.logCustomEvent("TMN Coin - Error", {
    "Error Code": errorCode,
    "Error Message": errorMessage
  })
}

export const tackTMNViewCouponDetail = async ({
  tmnId,
  couponId,
  couponName,
  price,
  couponStage,
  couponType,
  merchantName
}: {
  tmnId: string
  couponId: string
  couponName: string
  price: number
  couponStage: string
  couponType: string
  merchantName: string
}) => {
  // Mixpanel
  if (tmnId) {
    mixpanel.identify(tmnId)
  }

  mixpanel.track("TMN Coin - View Coupon Detail", {
    "Coupon ID": couponId,
    "Coupon name": couponName,
    Price: price,
    "Coupon Stage": couponStage,
    "Coupon type": couponType,
    "Merchant name": merchantName
  })
  // Braze
  if (tmnId) {
    braze.changeUser(tmnId)
  }
  braze.logCustomEvent("TMN Coin - View Coupon Detail", {
    "Coupon ID": couponId,
    "Coupon name": couponName,
    Price: price,
    "Coupon Stage": couponStage,
    "Coupon type": couponType,
    "Merchant name": merchantName
  })
}

export enum TabName {
  Received = "Received",
  Used = "Used",
  Expired = "Expired"
}
export const trackViewHistoryPage = async (tabName: TabName) => {
  // Mixpanel
  mixpanel.track("TMN Coin - View History page", {
    "Tab name": tabName
  })
  // Braze
  braze.logCustomEvent("TMN Coin - View History page", {
    "Tab name": tabName
  })
}

export const trackViewMarketplacePage = async () => {
  mixpanel.track("TMN Coin - View Redemption page")
  braze.logCustomEvent("TMN Coin - View Redemption page")
}

export const trackViewMarketplaceSectionPage = async (sectionName: string) => {
  mixpanel.track("TMN Coin - View Marketplace section page", {
    "Section Name": sectionName
  })
  braze.logCustomEvent("TMN Coin - View Marketplace section page", {
    "Section Name": sectionName
  })
}

export const trackViewCouponsPage = async (type: string) => {
  mixpanel.track("TMN Coin - View My Coupon page", {
    "Tab name": type
  })
  braze.logCustomEvent("TMN Coin - View My Coupon page", {
    "Tab name": type
  })
}
