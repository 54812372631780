import axiosInstance from "@/utils/axiosInstance"

interface Pagination {
  skip: number
  limit: number
}

interface CouponsRequest extends Pagination {
  tokenType?: string
  fromDateTime?: number
  toDateTime?: number
  sort?: string
  dealType?: string
  dealStatuses?: string
}

interface UsedCouponsRequest extends Pagination {
  sort?: string
  fromDateTime?: number
  toDateTime?: number
}

interface ExpiredCouponsRequest extends Pagination {
  sort?: string
  fromDateTime?: number
  toDateTime?: number
}

interface CashbackMetadata {
  id: string
  locale: string
  name: string
  description: string
  image: string
  prefix: string
  campaignId: string
  campaignName: string
  shortDescription: string
  tnc: string
  value: number
}

export interface AsCaper {
  uri: string
  periodSeconds: number
  redemptionsPerPeriod: number
  redeemImmediately: boolean
  metadata: CashbackMetadata[]
}

export interface DealMetadata {
  id: string
  locale: string
  name: string
  description: string
  image: string
  externalUrl: string
  startDateTime: string
  endDateTime: string
  tagline: string
  price: number
  value: number
  redemptionCTA: string
  dealStatus: string
  tnc: string
  thumbnail: string
  bgPicture: string
  dealType: string
  merchantImage: string
  merchantName: string
  code?: {
    code: string
    format: string
  }
  txHash?: string
  merchantWalletAddress?: string
  categoryName: string
}

export interface AsDeal {
  id: string
  uri: string
  maxSupply: number
  redeemEndDate: number
  redeemStartDate: number
  totalSupply: number
  code: {
    code: string
    format: string
  }
  merchantWalletAddress: string
  metadata: DealMetadata[]
}

export interface Transaction {
  id: string
  contractAddress: string
  tokenId: string
  owner: string
  balance: number
  tokenType: TokenType
  asCaper: AsCaper
  asDeal: AsDeal
}

export enum TokenType {
  Deals = "Deals",
  Caper = "Caper"
}

export interface UsedTransaction {
  id: string
  txHash: string
  logIndex: number
  owner?: string
  contract: string
  tokenId: string
  amount: number
  transferAt: string
  transferType: string
  tokenType: TokenType
  asCaper: AsCaper
  asDeal: AsDeal
}

interface CouponTransactionResponse {
  owner: string
  items: Transaction[]
  skip: number
  limit: number
}

interface UsedCouponTransactionResponse {
  owner: string
  items: UsedTransaction[]
  skip: number
  limit: number
}

export interface UsedVoucherResponse {
  asDeal: AsDeal
  balance: number
  contractAddress: string
  id: string
  owner: string
  tokenId: string
  tokenType: TokenType
}

export async function getMyCoupons({
  tokenType,
  sort,
  fromDateTime,
  toDateTime,
  dealStatuses,
  dealType,
  skip,
  limit
}: CouponsRequest) {
  try {
    const response = await axiosInstance.get<CouponTransactionResponse>("/my-coupons", {
      params: {
        tokenType,
        sort,
        fromDateTime,
        toDateTime,
        dealStatuses,
        dealType,
        skip,
        limit
      }
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getMyUsedCoupons({
  sort,
  fromDateTime,
  toDateTime,
  skip,
  limit
}: UsedCouponsRequest) {
  try {
    const response = await axiosInstance.get<UsedCouponTransactionResponse>(
      "/used-coupons",
      { params: { sort, fromDateTime, toDateTime, skip, limit } }
    )
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getMyExpiredCoupons({
  sort,
  fromDateTime,
  toDateTime,
  skip,
  limit
}: ExpiredCouponsRequest) {
  try {
    const response = await axiosInstance.get<CouponTransactionResponse>(
      "/expired-coupons",
      {
        params: {
          sort,
          fromDateTime,
          toDateTime,
          skip,
          limit
        }
      }
    )
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

async function getCoupon({
  contractAddress,
  tokenId
}: {
  contractAddress: string
  tokenId: string
}) {
  try {
    const response = await axiosInstance.get<Transaction>(
      `/coupons/${contractAddress}/${tokenId}`
    )

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

async function getUsedCoupon({
  contractAddress,
  tokenId,
  txHash
}: {
  contractAddress: string
  tokenId: string
  txHash: string
}) {
  try {
    const response = await axiosInstance.get<UsedVoucherResponse>(
      `/coupons/${contractAddress}/${tokenId}/${txHash}`
    )

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

const couponAPI = {
  getMyCoupons,
  getMyUsedCoupons,
  getMyExpiredCoupons,
  getCoupon,
  getUsedCoupon
}

export default couponAPI
