import * as braze from "@braze/web-sdk"

export type BrazeToken = {
  apiKey: string
  restEndpoint: string
}

export const initBraze = (
  { apiKey, restEndpoint }: BrazeToken,
  isEnableTrackingDebug: boolean
) => {
  braze.initialize(apiKey, {
    baseUrl: restEndpoint,
    enableLogging: isEnableTrackingDebug
  })
  return braze
}

export default braze
