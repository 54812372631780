import {
  ConsoleInstrumentation,
  ConsoleTransport,
  ErrorsInstrumentation,
  FetchTransport,
  initializeFaro,
  // LogLevel,
  SessionInstrumentation,
  // WebVitalsInstrumentation,
  Faro
} from "@grafana/faro-web-sdk"

// import { TracingInstrumentation } from "@grafana/faro-web-tracing";

export type FaroToken = {
  apiKey: string
  appName: string
  url: string
  isEnable: boolean
}

// const instrumentationOptions = {
//   propagateTraceHeaderCorsUrls: [new RegExp('BACKEND_SERVER(S)_REGEX')], // This is a list of specific URIs or regular exprressions
// };

let faro: null | Faro = null

const initFaro = (faroToken: FaroToken) => {
  if (!faroToken) {
    return
  }
  const { url, apiKey, appName, isEnable } = faroToken
  if (!isEnable || !url || !apiKey || !appName) {
    return
  }

  faro = initializeFaro({
    instrumentations: [
      new ErrorsInstrumentation(),
      // new WebVitalsInstrumentation(),
      new ConsoleInstrumentation(),
      new SessionInstrumentation()
    ],
    transports: [
      new FetchTransport({
        url,
        apiKey
      }),
      new ConsoleTransport()
    ],
    app: {
      name: appName
    }
  })
}

export { initFaro, faro }
