import { useTranslation } from "react-i18next"
import { useNavigate } from "@tanstack/react-router"
import { Tab } from "../route.lazy"

interface Props {
  variant: keyof typeof Tab
}

const NoTransaction = ({ variant }: Props) => {
  const { t } = useTranslation(["coupon"])
  const navigate = useNavigate()

  const variants = {
    received: {
      title: t("noUsableTransactionTitle"),
      desc: t("noUsableTransactionDesc")
    },
    used: {
      title: t("noUsedTransactionTitle"),
      desc: t("noUsedTransactionDesc")
    },
    expired: {
      title: t("noExpiredTransactionTitle"),
      desc: t("noExpiredTransactionDesc")
    }
  }

  const { title, desc } = variants[variant]
  return (
    <div className="flex justify-center items-center flex-col gap-6">
      <img
        data-testid="no-coupon-transaction-img"
        alt="no-coupon-transaction"
        src="/images/no-coupon-transaction.png"
        loading="lazy"
        width={280}
        height={184}
      />
      <div className="flex justify-center items-center flex-col gap-2">
        <h2 data-testid="no-coupon-transaction-title" className="headline text-center">
          {title}
        </h2>
        <span
          data-testid="no-coupon-transaction-desc"
          className="body-large text-tmn-black-tint-30 text-center"
        >
          {desc}
        </span>
      </div>
      <button
        data-testid="search-coupon-button"
        className="border-solid border border-[#ff8724] rounded-[100px] py-2 px-4 text-tmn-orange-tint-0"
        type="button"
        onClick={() => navigate({ to: "/exchange" })}
      >
        {t("searchCouponButton")}
      </button>
    </div>
  )
}

export default NoTransaction
