import { Outlet, createFileRoute } from "@tanstack/react-router"
import CommonLayout from "./_common-layout/-components/CommonLayout"
import UnderMA from "./_main-layout/-components/UnderMA"
import useAppConfigStore from "@/stores/useAppConfigStore"

export const Route = createFileRoute("/_common-layout")({
  component: LayoutComponent
})

function LayoutComponent() {
  const { isEnableMAMKPCoupon } = useAppConfigStore()

  return (
    <CommonLayout>
      {/* <div id="tmn-hide-navbar"></div> */}
      {isEnableMAMKPCoupon && <UnderMA />}
      {!isEnableMAMKPCoupon && <Outlet />}
    </CommonLayout>
  )
}
