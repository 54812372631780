import { useInfiniteQuery } from "@tanstack/react-query"

//APIs
import { getMyUsedCoupons } from "@/api/coupon.api"

const FETCH_MY_USED_COUPONS = "FETCH_MY_USED_COUPONS"

interface Props {
  limit?: number
  enabled?: boolean
}

const useFetchMyUsedCoupons = ({ limit = 10, enabled = true }: Props) => {
  return useInfiniteQuery({
    queryKey: [FETCH_MY_USED_COUPONS],
    queryFn: async ({ pageParam }) => {
      const skip = limit * pageParam
      const response = await getMyUsedCoupons({
        sort: "transferAt:desc",
        skip,
        limit
      })
      const nextPage = response.items.length > 0 ? pageParam + 1 : undefined
      return { data: response, nextPage }
    },
    initialPageParam: 0,
    getNextPageParam: lastPage => lastPage.nextPage ?? undefined,
    refetchOnWindowFocus: "always",
    enabled
  })
}

export default useFetchMyUsedCoupons
