export enum Category {
  "F&B" = "F&B",
  "Shopping" = "Shopping",
  "Entertainment" = "Entertainment",
  "Donation" = "Donation",
  "LuckyDraw" = "Lucky Draw",
  "Travel" = "Travel",
  "Beauty" = "Beauty",
  "Financial" = "Financial",
}
